<template>
  <div>
    <h4>
      <b-button
        v-b-modal.modal-rule-to-country
        variant="secondary"
        size="sm"
      >
        Assign rules
      </b-button>
      System rules</h4>
    <div v-if="rules.length > 0">
      <b-row class="justify-content-between mb-2">
        <b-col lg="6">
          <b-form-group
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Search by rule"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        :items="rules"
        :fields="fields"
        :filter="filter"
        :filter-included-fields="filterOn"
        striped
        responsive
        small
        :per-page="perPage"
        :current-page="currentPage"
        @filtered="onFiltered"
      />
      <pagination-row />
    </div>
    <p v-else>
      No selected rules yet.
    </p>

    <b-modal
      id="modal-rule-to-country"
      ref="modal-rule-to-country"
      cancel-variant="outline-secondary"
      ok-title="Create"
      cancel-title="Close"
      centered
      size="lg"
      title="Assign rules to country"
      :no-close-on-backdrop="true"
      @ok="handleOk"
    >
      <multi-entity-select
        v-model="formRules"
        :filter="ruleFilter"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BModal, BRow, BTable,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PaginationRow from '@/components/ui/tables/PaginationRow.vue'
import table from '@/mixins/table'
import MultiEntitySelect from '@/components/ui/gqlforms/MultiEntitySelect.vue'
import SettingsAPI from '@/api/settings'

export default {
  components: {
    MultiEntitySelect,
    BModal,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BButton,
    PaginationRow,
    BTable,
  },
  mixins: [table],
  props: {
    rules: [Array, Object],
    countryId: String,
  },
  data() {
    return {
      loading: false,
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'type',
          sortable: true,
        },
        {
          key: 'description',
          sortable: true,
        },
      ],
      filterOn: ['name', 'type', 'description'],
      filter: null,
      formRules: { ...this.rules },
      ruleFilter: {
        name: 'rules',
        label: 'System rules',
        filterable: {
          type: 'entity', queryType: 'rules', label: 'name', search: 'name', value: 'id',
        },
      },
    }
  },
  watch: {
    rules() {
      this.totalRows = this.rules.length
    },
  },
  created() {
    this.totalRows = this.rules.length
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    handleOk() {
      SettingsAPI.patchCountry(this.countryId, { system_rules: this.formRules })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated',
              icon: 'SendIcon',
              text: 'Rules settings was change',
              variant: 'success',
            },
          })
          this.$emit('updated')
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update failed',
              icon: 'SendIcon',
              text: 'Rules settings failed',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
