<template>
  <b-modal
    id="modal-product-to-country"
    ref="modal-product-to-country"
    cancel-variant="outline-secondary"
    ok-title="Create"
    cancel-title="Close"
    centered
    size="lg"
    title="Assign products to country"
    :no-close-on-backdrop="true"
    @ok="handleOk"
  >
    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <b-form
        v-if="!loading"
        ref="product-form"
      >
        <label>Products</label>
        <product-tree-select
          :products-list="products"
          @selected="value => productIds = value"
        />
      </b-form>
    </b-overlay>
  </b-modal>
</template>
<script>
import {
  BModal, BForm, VBModal, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formatCurrency } from '@/plugins/formaters'
import ProductTreeSelect from '@/components/ui/forms/ProductTreeSelect.vue'
import SettingsAPI from '@/api/settings'

export default {
  components: {
    ProductTreeSelect,
    BModal,
    BForm,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    products: [Array, Object],
    countryId: String,
  },
  data() {
    return {
      loading: false,
      productIds: [],
    }
  },
  methods: {
    formatCurrency,
    handleOk(event) {
      // prevent modal before immediate closing
      event.preventDefault()

      this.loading = true

      const products = this.productIds.map(p => ({ id: p }))

      SettingsAPI.patchCountry(this.countryId, { allowed_products: products }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated',
            icon: 'SendIcon',
            text: 'Products was updated',
            variant: 'success',
          },
        })
        this.$refs['modal-product-to-country'].hide()
        this.$emit('updated')
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
