<template>
  <b-overlay :show="loading">
    <b-card v-if="country">
      <b-card-header>
        <b-card-title>
          <b-button
            v-b-modal.modal-product-to-country
            size="sm"
            variant="primary"
          >
            Assign products
          </b-button> |
          {{ country.name }}</b-card-title>
      </b-card-header>
      <hr>
      <b-card-body>
        <products-table :products="country.allowed_products" />
        <hr class="my-2">
        <account-type-settings-table :country-id="countryId" />
        <hr class="my-2">
        <system-rules-table
          :country-id="countryId"
          :rules="country.system_rules"
          @updated="load"
        />
      </b-card-body>
      <products-to-country-modal
        :country-id="country.id"
        :products="country.allowed_products"
        @updated="load"
      />
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BButton, BCard, BCardHeader, BCardTitle, BCardBody, BOverlay,
} from 'bootstrap-vue'
import SettingsAPI from '@/api/settings'
import ProductsTable from '@/views/settings/country-settings/detail/components/ProductsTable.vue'
import ProductsToCountryModal from '@/views/settings/country-settings/detail/components/ProductsToCountryModal.vue'
import AccountTypeSettingsTable from '@/views/settings/country-settings/detail/components/AccountTypeSettingsTable.vue'
import SystemRulesTable from '@/views/settings/country-settings/detail/components/SystemRulesTable.vue'

export default {
  components: {
    SystemRulesTable,
    AccountTypeSettingsTable,
    ProductsToCountryModal,
    BButton,
    ProductsTable,
    BCardHeader,
    BCardTitle,
    BCard,
    BCardBody,
    BOverlay,
  },
  data() {
    return {
      country: null,
      loading: false,
    }
  },
  computed: {
    countryId() {
      return this.$route.params.id
    },
  },
  beforeMount() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      SettingsAPI.getCountryDetail(this.countryId).then(response => {
        this.country = response
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
