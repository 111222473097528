<template>
  <div>
    <div v-if="products.length > 0">
      <b-row class="justify-content-between mb-2">
        <b-col lg="6">
          <b-form-group
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Search by product"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        :items="products"
        :fields="fields"
        :filter="filter"
        :filter-included-fields="filterOn"
        striped
        responsive
        small
        :per-page="perPage"
        :current-page="currentPage"
        @filtered="onFiltered"
      >
        <template #cell(name)="data">
          <span class="font-weight-bold">{{ data.item.platform }}</span> | {{ data.item.name }}
        </template>

        <template #cell(price)="data">
          {{ formatCurrency(data.item.price, 'USD') }}
        </template>
      </b-table>
      <pagination-row />
    </div>
    <p v-else>
      No selected products yet.
    </p>
  </div>
</template>

<script>
import {
  BButton, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BRow, BTable,
} from 'bootstrap-vue'
import PaginationRow from '@/components/ui/tables/PaginationRow.vue'
import table from '@/mixins/table'
import { formatCurrency } from '@/plugins/formaters'

export default {
  components: {
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BButton,
    PaginationRow,
    BTable,
  },
  mixins: [table],
  props: {
    products: [Array, Object],
  },
  data() {
    return {
      loading: false,
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'price',
          sortable: true,
        },
      ],
      filterOn: ['name', 'platform'],
      filter: null,
    }
  },
  watch: {
    products() {
      this.totalRows = this.products.length
    },
  },
  created() {
    this.totalRows = this.products.length
  },
  methods: {
    formatCurrency,
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
