import axiosInstance from '@/plugins/axios'

export default {
  getCountryDetail(countryId) {
    const url = `/admin/settings/countries/${countryId}`

    return axiosInstance.get(url)
      .then(response => response.data)
  },

  getCountryAccountSettings(countryId) {
    const url = `/admin/settings/countries/${countryId}/account-type-settings`

    return axiosInstance.get(url)
      .then(response => response.data)
  },

  patchCountry(countryId, data) {
    const url = `/admin/settings/countries/${countryId}`

    return axiosInstance.patch(url, data)
      .then(response => response.data)
  },

  postCountryAccountSettings(countryId, data) {
    const url = `/admin/settings/countries/${countryId}/account-type-settings`

    return axiosInstance.post(url, data)
      .then(response => response.data)
  },

  deleteCountryAccountSettings(settingsId) {
    const url = `/admin/settings/countries/account-type-settings/${settingsId}`

    return axiosInstance.delete(url)
      .then(response => response.data)
  },
}
